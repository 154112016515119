<template>
  <div v-if="!loading">
    <b-container class="p-0">
      <b-row
        align-content="center"
        align-h="center"
        :cols="hasStepGuide ? 6 : 3"
        class="mt-2"
        :class="hasStepGuide ? 'p-0' : 'py-3'"
      >
        <b-col
          cols="12"
          :md="hasStepGuide ? 11 : 7"
          :lg="hasStepGuide ? 9 : 5"
          class="text-center top-up-in-process"
        >
          <div class="header px-2 pb-1">
            <img
              alt="success"
              :src="require('@/assets/images/pages/payment/inprogress-payment.png')"
            >
            <h3 class="my-1">
              {{ $t('payment.airWallex.textTopupInprogress') }}
            </h3>
            <p>{{ $t('payment.airWallex.subTitleTopUpInprogress') }}!</p>
          </div>

          <div class="summary p-2 card">
            <h4 class="text-left mb-2">
              {{ $t('addFundPage.creditCardPayment.textSummary') }}
            </h4>
            <div class="mb-1 d-flex flex-row flex-wrap justify-content-between">
              <div class="font-14 text-placeholder">
                {{ $t('addFundPage.creditCardPayment.textClient') }}
              </div>
              <strong>
                {{ fullName }}
              </strong>
            </div>
            <div class="mb-1 d-flex flex-row justify-content-between">
              <div class="font-14 text-placeholder">
                {{ $t('payment.airWallex.labelOrderAmount') }}
              </div>
              <strong>
                {{ currencyFormat(amount) }} USD
              </strong>
            </div>
            <div class="mb-1 d-flex flex-row justify-content-between">
              <div class="font-14 text-placeholder">
                {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
              </div>
              <img
                :src="lianlianLogo"
                height="16px"
                alt="lianlian"
              >
            </div>

            <div
              v-if="serviceCharge"
              class="d-flex flex-row justify-content-between mb-1"
            >
              <div class="font-14 text-placeholder">
                {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
              </div>
              <strong class="text-primary">{{ currencyFormat(serviceCharge) }} USD</strong>
            </div>

            <div
              v-if="lianlianFee"
              class="d-flex align-items-center justify-content-between mb-1"
            >
              <div class="font-14 text-placeholder">
                {{ $t('payment.paypal.textPaymentFee', {payment:"LianLian"}) }}
              </div>
              <div>
                <div
                  class="d-flex align-items-center"
                >
                  <strong class="text-primary">{{ currencyFormat(lianlianFee) }} USD</strong>
                </div>
              </div>
            </div>

            <div
              v-if="tax"
              class="d-flex flex-row justify-content-between"
            >
              <div class="font-14 text-placeholder">
                {{ $t('payment.balanceTransactionsTable.textTax') }}
              </div>
              <strong class="text-primary">{{ currencyFormat(tax) }} USD</strong>
            </div>

            <div
              v-if="tipAMAmount > 0"
              class="d-flex align-items-center justify-content-between mt-1"
            >
              <div class="font-14 text-placeholder">
                Tip
              </div>
              <strong class="text-primary">${{ tipAmountTxt }} USD</strong>
            </div>

            <hr class="hr-custom">
            <div class="d-flex align-items-center justify-content-between">
              <div class="font-14 text-placeholder text-left">
                {{ $t('addFundPage.creditCardPayment.textTotalAmount') }} = {{ $t('balance.textAmount') }}
                {{ serviceCharge ? ` + ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : '' }}
                {{ lianlianFee ? ` + ${$t('payment.balanceTransactionsTable.textTax')}` : '' }}
                {{ tax ? ` + ${$t('payment.paypal.textPaymentFee', { payment: 'LianLian' })}` : '' }}
                {{ tipAMAmount > 0 ? ' + Tip' : '' }}
              </div>
            </div>
            <h3 class="mt-2 text-left">
              {{ currencyFormat(bankTransferAmount) }} USD
            </h3>
          </div>

          <div
            v-if="!isInProgress"
            class="action mt-2 d-flex flex-column flex-sm-row justify-content-between align-items-center action-wrap"
          >
            <btn-loading
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant-convert="btn-submit"
              class="w-100"
              pill
              @click="$router.push('/payment/add-fund?tab=lianlian')"
            >
              {{ $t('payment.textRecharge') }}
            </btn-loading>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  BContainer, BRow, BCol,
} from 'bootstrap-vue'
import _get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import Ripple from 'vue-ripple-directive'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    BContainer,
    BRow,
    BCol,
  },

  directives: {
    Ripple,
  },

  mixins: [stepGuideMixin, numberFormatMixin, paymentMixin],

  props: {
    isInProgress: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGettersAuth(['user', 'loading', 'generalConfigs']),
    ...mapGetters(['lianlianPending']),

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    lianlianLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-lianlian-full.svg')
    },

    amount() {
      return this.lianlianPending?.amount
    },

    bankTransferAmount() {
      return this.lianlianPending?.payload?.bankTransferAmount
    },

    paymentMethod() {
      return this.lianlianPending.paymentMethod
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.lianlian || 0
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.lianlianGate || 0
    },

    serviceCharge() {
      return this.lianlianPending?.payload?.serviceCharge
    },

    lianlianFee() {
      return this.lianlianPending?.payload?.paymentGateCharge
    },

    tax() {
      return this.lianlianPending?.payload?.taxCharge
    },

    tipAMAmount() {
      return this.lianlianPending?.payload?.tipAMAmount || null
    },

    tipAmountTxt() {
      return this.amountFormat(this.tipAMAmount).toLocaleString('en-US')
    },
  },

  created() {
    if (!this.lianlianPending?.payload) {
      this.getLianlianPending()
    }
  },

  methods: {
    ...mapActions(['getLianlianPending']),

    goToGuide() {
      window.open(process.env.VUE_APP_GUIDE_TO_TOP_UP_USDT)
    },
  },
}
</script>

<style lang="scss" scoped>
.container-box {
  max-width: 340px;
  margin: 0 auto;
}

.summary {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  padding: 24px 17px;
}

.btn-recharge{
  font-size: 14px;
  padding: 11px 20px;
  width: 150px;
}

.top-up-in-process{
  max-width: 410px;
  margin: 0;
  padding: 0;
}
</style>
